import GridOutline from '../Components/GridOutline';
import AnimatedPage from '../Components/AnimatedPage';
// import StaggerAnimate from '../Components/StaggerAnimate';
import { motion } from 'framer-motion';

export default function HomePage() {
  return (
    <AnimatedPage>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: 'easeIn' }}
      >
        <HomeBanner />
        <Projects />
      </motion.div>
    </AnimatedPage>
  );
}

function HomeBanner() {
  return (
    <div className='relative flex h-screen flex-row items-stretch justify-center self-stretch bg-cover text-neutral-600 lg:rounded-b-sm lg:shadow-md '>
      {/* <img
        src='/assets/self.jpg'
        alt='self'
        className='absolute -z-[100] h-5/6 w-full bg-cover bg-center bg-no-repeat opacity-50 lg:h-full'
      /> */}
      <div
        className='absolute h-5/6 w-full shrink-0 lg:h-full'
        style={{
          backgroundImage: `url(${'/assets/self.jpg'})`,
          opacity: 0.5,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1,
        }}
      ></div>

      <GridOutline>
        <div className='p-2 p-4 xl:p-8'>
          <div className='mt-2 mb-12 flex grow flex-col items-center justify-center space-y-8 md:space-y-16 lg:mb-20'>
            <p className='justify-center pr-10 pt-2 font-libre text-7xl font-extrabold tracking-normal md:pr-36 md:pt-12'>
              Hello, I'm
            </p>
            <p className='bold xl:px-18 space-x-2 font-libre text-6xl lg:text-7xl xl:text-8xl'>
              <span className='font-extrabold tracking-tight text-emerald-900/100 underline decoration-emerald-900 decoration-2 underline-offset-8'>
                Christine,
              </span>
              <span className='space-x-2 font-libre text-3xl tracking-tight lg:text-4xl xl:text-5xl'>
                a data-driven developer,
              </span>
            </p>
            <p className='text-center font-crimson text-4xl tracking-normal underline decoration-neutral-100 decoration-2 underline-offset-4 md:text-5xl lg:text-right xl:pl-[34rem]'>
              <span>based in the </span>
              <span className='relative inline-block py-2 before:absolute before:-inset-1 before:block before:-skew-y-6 before:bg-teal-900'>
                <span className='relative px-2 text-6xl italic text-neutral-50 transition duration-300 hover:bg-yellow-100 hover:text-neutral-700 hover:ease-out'>
                  Bay Area.
                </span>
              </span>
            </p>
          </div>
        </div>
      </GridOutline>
    </div>
  );
}

function Projects() {
  return (
    <div>
      <div className='p-4 pb-12 md:pb-32 lg:pb-40'>
        <div className="my-8 flex items-center gap-4 font-libre text-4xl before:h-px before:flex-1 before:bg-gray-300  before:content-[''] md:p-8 md:text-6xl lg:text-7xl">
          FIRST LOOK
        </div>

        <div className='grid place-content-center justify-center gap-4 px-4 pb-8 md:grid-cols-3'>
          <div className='rounded font-libre shadow-md'>
            <div className='p-2'>
              <h3 className='font-trispace text-xl font-medium text-gray-900 md:text-2xl'>
                01. CURRENT PROJECTS
              </h3>
              <p className='mt-1 p-2 font-karla text-gray-500'>
                <ul className='space-y-2'>
                  <li>
                    <span className='pr-2 font-bold text-emerald-900 underline decoration-emerald-900 underline-offset-2'>
                      PORTFOLIO WEBSITE:
                    </span>
                    Roughly wireframed in Figma. Developed in VS Code, built with TypeScript,
                    React.js, framer-motion, HTML5, & Tailwind CSS. Deployed with Firebase.
                  </li>
                  <li>
                    <span className='pr-2 font-bold text-emerald-900 underline decoration-emerald-900 underline-offset-2'>
                      WEATHER WEB-APP:
                    </span>
                    Currently building with Figma, VS Code, Vite.js, TypeScript, Tailwind CSS,
                    open-meteo.com, Google-Maps-React/Geocoding API
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className='rounded font-libre shadow-md'>
            <div className='p-2'>
              <h3 className='font-trispace text-xl font-medium text-gray-900 md:text-2xl'>
                02. DEV SKILLS
              </h3>
              <p className='mt-1 p-2 font-karla text-gray-500'>
                <ul className='space-y-2'>
                  <li>
                    <span className='pr-2 font-bold text-emerald-900 underline decoration-emerald-900 underline-offset-2'>
                      PROGRAMMING LANGUAGES:
                    </span>
                    Javascript, Typescript, Python, SQL, HTML5, CSS, Tailwind CSS
                  </li>
                  <li>
                    <span className='pr-2 font-bold text-emerald-900 underline decoration-emerald-900 underline-offset-2'>
                      LIBRARIES & PACKAGES:
                    </span>
                    GitHub, npm, framer-motion, react-spring
                  </li>
                  <li>
                    <span className='pr-2 font-bold text-emerald-900 underline decoration-emerald-900 underline-offset-2'>
                      FRAMEWORKS:
                    </span>
                    React, Vite.js, Node.js
                  </li>
                  <li>
                    <span className='pr-2 font-bold text-emerald-900 underline decoration-emerald-900 underline-offset-2'>
                      TECH:
                    </span>
                    VS Code, Jupyter Notebook, PyCharm, Microsoft SQL Server, MySQL, MS Office
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className='rounded font-libre shadow-md'>
            <div className='p-2'>
              <h3 className='font-trispace text-xl font-medium text-gray-900 md:text-2xl'>
                03. DESIGN
              </h3>
              <p className='mt-1 p-2 font-karla text-gray-500'>
                <ul className='space-y-2'>
                  <li>UX/UI</li>
                  <li>Responsive Web Design</li>
                  <li>Typography</li>
                  <li>Figma</li>
                  <li>Chrome DevTools</li>
                  <li>Google Fonts</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='h-500 w-500 group/item inline-block transform rounded px-4 lg:py-4'>
        {/* <!-- transition duration-500 hover:scale-110 hover:bg-neutral-100"
          > --> */}
        {/* <h1 className='py-12 pb-8 text-center font-libre text-5xl underline decoration-1 underline-offset-8 lg:pb-14 xl:py-14'>
        after:h-px after:flex-1 after:bg-gray-300 after:content-[''] md:p-8
          PAST PROJECTS
        </h1> */}

        <div className='my-8 flex items-center gap-4 px-4 font-libre text-4xl md:text-6xl lg:text-7xl'>
          PAST PROJECTS
        </div>
        <hr className='my-8 h-px border-0 bg-gray-300' />
        <div className='mb-16 flex flex-col md:mx-12'>
          <div className='mb-8 flex flex-col md:flex-row'>
            <div className='relative order-1 mr-10 pt-2 font-trispace font-medium md:w-1/2 '>
              <div className='absolute relative inset-0 z-0 justify-center rounded-sm bg-contain bg-center bg-no-repeat shadow-none'>
                <img
                  src='/assets/ML_graph1.png'
                  alt='ML graph'
                  className='h-full w-full shrink-0'
                />
                <div className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-white bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-40'></div>
              </div>
              <div className='absolute inset-0 z-10 flex flex-col justify-end space-y-2 rounded-sm pb-24 pr-8 text-right font-noto text-3xl font-semibold text-zinc-50 opacity-0 duration-300 hover:bg-neutral-500 hover:opacity-70'>
                <p className='pt-4 text-4xl font-bold'>{'// Machine Learning with Python'}</p>
                <p className='italic'>[January 2021 - May 2021]</p>
                <p className='underline underline-offset-8 hover:decoration-double'>
                  University of Texas at Dallas
                </p>
              </div>
            </div>
            <div className='text-md font-mono order-2 md:w-1/2'>
              <div className='justify-items-center lg:pb-20 xl:py-40'>
                <h3 className='py-4 font-trispace text-2xl font-medium tracking-wider text-neutral-800 lg:text-3xl'>
                  {/* took out underline design: underline decoration-emerald-900 decoration-dotted underline-offset-4 */}
                  DETERMINING MOST INFLUENTIAL FACTORS IN ARTICLE POPULARITY
                </h3>
                <ul className='list-disc space-y-3 pl-3 font-karla text-xl font-light marker:text-stone-500'>
                  <li>
                    Evaluated ~40,000 news articles to determine the factors driving popularity,
                    defined as number of shares
                  </li>
                  <li>
                    Determined with high confidence that the subject matter and including keywords
                    in titles was most significant of the factors tested
                  </li>
                  <li>
                    Utilized a Python machine learning model to test and select the most reliable
                    classification approach between DBSCAN, K-Means Clustering, Naïve-Bayes, and PCA
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='mb-8 flex flex-col md:flex-row'>
            <div className='relative order-1 ml-10 font-trispace font-medium md:order-2 md:w-1/2'>
              <div className='absolute relative inset-0 z-0 justify-center rounded-sm bg-contain bg-center bg-no-repeat shadow-none'>
                <img
                  src='/assets/airbnb_graph.png'
                  alt='AirBnB Graph'
                  className='h-full w-full shrink-0 py-8 md:py-20'
                />
              </div>
              <div className='absolute inset-0 z-10 flex flex-col justify-start space-y-2 rounded-sm pt-24 pr-10 text-right font-noto text-3xl font-semibold text-zinc-50 opacity-0 duration-300 hover:bg-neutral-500 hover:opacity-70'>
                <p className='text-4xl font-bold'>{'// Programming for Data Science'}</p>
                <p className='italic'>[August 2020 - December 2020]</p>
                <p className='underline underline-offset-8 hover:decoration-double'>
                  University of Texas at Dallas
                </p>
              </div>
            </div>
            <div className='text-md font-mono order-2 md:order-1 md:w-1/2'>
              <div className='justify-items-center md:py-20 xl:py-40'>
                <h3 className='py-4 font-trispace text-2xl font-medium tracking-wider text-neutral-800 lg:text-3xl'>
                  {/* underline decoration-emerald-900 decoration-dotted underline-offset-4 */}
                  EXPLORING BEST CLASSIFIERS FOR SUCCESSFUL AIRBNB'S
                </h3>
                <ul className='list-disc space-y-3 pl-3 font-karla text-xl font-light marker:text-stone-500'>
                  <li>
                    Analyzed ~50,000 Airbnb listings in NYC to explore the relationship of price,
                    location, and 7 other factors with the success of the listing, defined as number
                    of completed reviews in 2019
                  </li>
                  <li>
                    Collaborated in a team of four to process, analyze, and visualize insights from
                    supervised and unsupervised learning algorithms used to classify successful
                    Airbnb's
                  </li>
                  <li>
                    Prepared a report detailing the impact of each variable after determining entire
                    home/apt & availability had the strongest impact while pricing had little to no
                    impact
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
