import React from 'react';
// import { Routes, Route } from 'react-router-dom';

import './App.css';
// import Cursor from './Components/Cursor';
// import HomePage from './Pages/HomePage';
// import AboutPage from './Pages/AboutPage';
// import Layout from './Pages/Layout';
// import ContactPage from './Pages/ContactPage';
import AnimatedRoutes from './Components/AnimatedRoutes';
import Footer from './Components/Footer';

function App() {
  return (
    <div className='App cursor-crosshair text-neutral-800'>
      {/* <Cursor /> */}
      {/* Route is only used for internal page */}

      <AnimatedRoutes />
      {/* <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='contact' element={<ContactPage />} />
        </Route>
      </Routes> */}
      <Footer />
    </div>
  );
}

// function App() {
//   return (
//     <div className='App'>
//       <div className='flex h-screen cursor-crosshair flex-col'>
//         <div className='grow bg-neutral-100 text-neutral-700'>
//           <div className='sticky top-0 z-10 mt-0 w-full bg-neutral-200 p-0.5'>
// Navbar component
//           </div>
//           <div id='content' className='container mx-auto'>
//             <div id='section-top'>
// Banner/Picture component
//             </div>
// Projects
// Resume Experience
//             <div className='mx-auto grid grid-cols-2 place-content-stretch items-center gap-x-8 gap-y-4'>
//               <div
//                 id='section-experience'
//                 className='h-500 w-500 group/item transform rounded px-6 pt-2 pb-4 transition duration-500 hover:scale-110 hover:bg-neutral-100'
//               >
//                 <h2 className='py-2 pl-2 font-trispace text-4xl font-semibold tracking-wider'>
//                   PROFESSIONAL EXPERIENCE
//                 </h2>
//                 <div className='place-content-stretch pt-2 font-trispace'>
//                   <div className='text-lg font-semibold italic'>
//                     Alkami Technology, Plano, TX [May 2022 - October 2022]
//                     <p className='pb-2 underline decoration-double underline-offset-4'>
//                       Associate Technical Implementation Engineer
//                     </p>
//                   </div>

//                   <div className='pb-2 text-base font-light'>
//                     <ul className='list-disc space-y-2 marker:text-stone-500'>
//                       <li>
//                         Self-taught SQL within 3 months and was able to independently configure at
//                         least 5 - 15% of tickets in team's dashboard
//                       </li>
//                       <li>
//                         Ensured that all ticket deadlines were met by end of weekly sprint for
//                         multiple project components
//                       </li>
//                       <li>
//                         Provided technical assistance to project managers & completed their
//                         troubleshooting error requests
//                       </li>
//                     </ul>
//                   </div>
//                 </div>

//                 <div className='place-content-stretch pt-2 font-trispace'>
//                   <div className='text-lg font-semibold italic'>
//                     ams Sensors USA, Inc., Plano, TX [May 2021 - October 2021]
//                     <p className='pb-2 underline decoration-double underline-offset-4'>
//                       Data Characterization & Validation Intern
//                     </p>
//                   </div>

//                   <div className='pb-2 text-base font-light'>
//                     <ul className='list-disc space-y-2 marker:text-stone-500'>
//                       <li>
//                         Proficiently navigated and operated hardware application systems related to
//                         APT User and Monochromator.vi
//                       </li>
//                       <li>
//                         Conducted over 100 tests on Monochromator.vi and created 60 large-scale
//                         visualizations on spectral response with varying temperatures and angles
//                       </li>
//                       <li>
//                         Performed comprehensive analysis of 20 testing variables using proprietary
//                         software to meet minimum threshold standards
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//                 <div className='place-content-stretch pt-2 font-trispace'>
//                   <div className='text-lg font-semibold italic'>
//                     Precise Circuits, Carrollton, TX [November 2016 - August 2019]
//                     <p className='pb-2 underline decoration-double underline-offset-4'>
//                       Electrical Engineering Technician
//                     </p>
//                   </div>
//                   <div className='pb-2 text-base font-light'>
//                     <ul className='list-disc space-y-2 marker:text-stone-500'>
//                       <li>
//                         Coordinated a client proposal through analyzing schematics and engineering
//                         product design as part of a $10,000 deal in new business
//                       </li>
//                       <li>
//                         Integrated a streamlined procedure for product assembly through original
//                         product creation, leading to a 30% increase in efficiency
//                       </li>
//                       <li>
//                         Maintained an electrical supply database through Excel regarding client
//                         orders to ensure smooth operational processes and to sustain inventory
//                         turnover rate
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               </div>

//               <div
//                 id='section-activities'
//                 className='h-500 w-500 group/item transform rounded bg-neutral-300 pl-6 pt-3 pb-5 pr-4 transition duration-500 hover:scale-110 hover:bg-neutral-100'
//               >
//                 <h2 className='py-2 font-trispace text-4xl font-semibold tracking-wider'>
//                   ACTIVITIES & LEADERSHIP
//                 </h2>
//                 <div className='place-content-stretch font-trispace'>
//                   <div className='text-lg font-bold italic'>
//                     TAMUhack, Dallas, TX [January 2021]
//                     <p className='pb-2 underline decoration-double underline-offset-4'>
//                       Cloud Pantry: Application Development Project - Participant
//                     </p>
//                   </div>
//                   <div className='pl-4 pb-2 text-base font-light'>
//                     <ul className='list-disc space-y-2 marker:text-stone-600'>
//                       <li>
//                         Developed an environmentally focused application with Python that would
//                         recognize duplicate items through cross analysis of pantry and shopping cart
//                         data and alert users to reduce food waste
//                       </li>
//                       <li>
//                         Applied object recognition techniques using OpenCV and TensorFlow to scan
//                         and upload photos of grocery items into a database
//                       </li>
//                       <li>
//                         Created a fully functional web-based application with database server
//                         connection using Django and PostgreSQL
//                       </li>
//                     </ul>
//                   </div>
//                   <div className='place-content-stretch font-trispace'>
//                     <div className='text-lg font-bold italic'>
//                       Filipino Student Association @ UT Dallas, Richardson, TX [August 2015 - May
//                       2018]
//                       <p className='pb-2 underline decoration-double underline-offset-4'>
//                         Family Leader
//                       </p>
//                     </div>
//                     <div className='pl-4 pb-2 text-base font-light'>
//                       <ul className='list-disc space-y-2 marker:text-stone-600'>
//                         <li>
//                           Accommodated in corporate outreach and programming for bi-monthly member
//                           meetings, social events, and community events
//                         </li>
//                         <li>
//                           Organized weekly outings and coordinated events to facilitate networking
//                           opportunities
//                         </li>
//                         <li>
//                           Encouraged and served as a mentor for new students which helped them feel
//                           more integrated on campus
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               {/* <div className="col-span-2 pt-20">
//               <div id="section-skills">
//                 <h4
//                   className="pt-10 pb-4 font-trispace text-3xl font-semibold tracking-wider"
//                 >
//                   _SKILLS & INTERESTS
//                 </h4>
//                 <div
//                   className="border-2 border-solid border-emerald-800 pt-3 pb-4 pr-3 pl-6 text-justify text-lg hover:border-dotted"
//                 >
//                   <ul className="space-y-2">
//                     <li>
//                       <span className="font-libre font-extrabold"
//                         >Programming Languages:</span
//                       ><span className="font-trispace font-light tracking-tighter">
//                         Python (NumPy, OpenCV, Pandas, Scikit-learn,
//                         Matplotlib), JavaScript, HTML/CSS, SQL, RStudio</span
//                       >
//                     </li>
//                     <li>
//                       <span className="font-libre font-extrabold">Tech:</span
//                       ><span className="font-trispace font-light tracking-tighter">
//                         Jupyter Notebook, Anaconda, VS Code, Microsoft SQL
//                         Server, PyCharm, MS Office</span
//                       >
//                     </li>
//                     <li>
//                       <span className="font-libre font-extrabold"
//                         >Work Eligibility:</span
//                       >
//                       <span className="font-trispace font-light tracking-tighter"
//                         >Eligible to work in the U.S. - Permanent Resident</span
//                       >
//                     </li>
//                     <li>
//                       <span className="font-libre font-extrabold">Interests:</span
//                       ><span className="font-trispace font-light tracking-tighter">
//                         PC building, mechanical keyboards, art museums,
//                         pen/pencil sketches, esports tournaments and gaming,
//                         classical violin & piano concertos</span
//                       >
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>  */}
// About Page Component
//          <div id='footer' className='mt-24 flex items-center justify-around bg-neutral-400'>
//            <h2 className='font-trispace text-2xl'>
//              THIS IS STILL A WORK IN PROGRESS -
//              <span className='font-noto text-sm italic'>please be patient, ty!</span>
//            </h2>
//            <p className='font-noto text-sm italic'>[email]: christine.lyliang@gmail.com</p>
//          </div>
//         </div>
//       </div>
//     </div>
//   );
// }

export default App;
