export default function Footer() {
  return (
    <footer className='m-4 pt-8'>
      <div className='mx-auto w-full max-w-screen-xl p-4 md:py-8'>
        {/* <div className='sm:flex sm:items-center sm:justify-between'>
          <a href='https://flowbite.com/' className='mb-4 flex items-center sm:mb-0'>
            <img
              src='https://flowbite.com/docs/images/logo.svg'
              className='mr-3 h-8'
              alt='Flowbite Logo'
            />
            <span className='self-center whitespace-nowrap text-2xl font-semibold dark:text-white'>
              Christine Liang
            </span>
          </a>
          <ul className='mb-6 flex flex-wrap items-center text-sm font-medium text-gray-500 dark:text-gray-400 sm:mb-0'>
            <li>
              <a href='#' className='mr-4 hover:underline md:mr-6 '>
                About
              </a>
            </li>
            <li>
              <a href='#' className='hover:underline'>
                Contact
              </a>
            </li>
          </ul>
        </div> */}
        <hr className='my-8 border-gray-200 dark:border-gray-700 sm:mx-auto lg:my-12' />
        <span className='block font-trispace text-sm font-light text-gray-500 dark:text-gray-400 sm:text-center'>
          © 2023{' '}
          <a href='https://christinelyliang.com/' className='hover:underline'>
            Christine Liang™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
