import { ReactNode } from 'react';

const light = {
  section: 'px-6 pt-2 pb-4',
  h2: '',
  bodyDiv: 'pt-2',
};

const dark = {
  section: 'bg-neutral-300 pl-6 pt-3 pb-5 pr-4',
  h2: '',
  bodyDiv: '',
};

interface Props {
  color: String;
  headerTitle: String;
  children: ReactNode;
}
export default function ResumeSection(props: Props) {
  return (
    <>
      <div
        className={`group/item transform rounded px-6 pt-2 pb-4 transition duration-500 hover:bg-neutral-100 ${
          props.color === 'light' ? light.section : dark.section
        }`}
      >
        <h2
          className={`py-2 font-trispace text-4xl font-semibold tracking-wider ${
            props.color === 'light' ? light.h2 : dark.h2
          }`}
        >
          {props.headerTitle}
        </h2>
        <div
          className={`place-content-stretch pt-2 font-karla ${
            props.color === 'light' ? light.bodyDiv : dark.bodyDiv
          }`}
        >
          {props.children}
        </div>
      </div>
    </>
  );
}
