import { AiFillLinkedin, AiFillGithub, AiFillMail, AiFillInstagram } from 'react-icons/ai';
// import Cursor from '../Components/Cursor';
import GridOutline from '../Components/GridOutline';
import AnimatedPage from '../Components/AnimatedPage';
// import StaggerAnimate from '../Components/StaggerAnimate';
import { motion } from 'framer-motion';

export default function ContactPage() {
  return (
    <AnimatedPage>
      {/* <Cursor /> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: 'easeIn' }}
      >
        <div className='bold flex grow flex-col items-center space-y-16 font-libre text-5xl tracking-wider '>
          <div className='container relative mx-auto flex flex-row items-stretch justify-center self-stretch'>
            <div
              className='absolute h-full w-full bg-blend-normal'
              style={{
                backgroundImage: `url(${'/assets/drawing1.PNG'})`,
                opacity: 0.25,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                zIndex: -1,
              }}
            ></div>
            <div className='px-2 md:grid md:grid-cols-2 md:grid-rows-3 md:gap-x-24 lg:gap-x-2 xl:gap-y-2'>
              <div className='group relative row-span-2 pt-12 font-extrabold underline-offset-2 lg:box-decoration-slice lg:pt-20'>
                <span className='text-left text-8xl text-zinc-700 xl:text-9xl'>LET'S GET </span>
                <br />
                <p className='p-2'></p>
                {/* </div>
          <div></div>
          <div> */}
                <span className='text-7xl italic tracking-widest text-emerald-900 xl:text-8xl'>
                  {'// in '}
                </span>
                <span className='relative text-8xl tracking-tighter text-zinc-700 xl:text-9xl xl:tracking-tight'>
                  TOUCH
                  <span className='absolute -bottom-1 left-0 h-0.5 w-0 bg-emerald-800 transition-all duration-500 group-hover:w-full'></span>
                </span>
              </div>

              <div className='container mx-auto md:col-start-2 md:row-start-2'>
                <GridOutline title='_MY SOCIALS @:'>
                  <div className='place-content-stretch space-y-4 px-4 text-zinc-700 lg:p-4 lg:pl-8 xl:p-12 xl:pl-16'>
                    {/* <p className='relative group font-trispace text-5xl font-bold tracking-wider underline-offset-1'>
              SOCIALS
              <span className='absolute -bottom-1 left-0 w-0 h-0 bg-zinc-200 transition-all duration-500 group-hover:w-full'></span>
            </p>
          </div>  */}
                    {/* <StaggerAnimate> */}
                    <ul className='space-y-2'>
                      <div>
                        <AiFillMail style={{ fontSize: '150%' }} className='inline-block' />
                        <span className='text-bold transform pl-2 text-zinc-800 transition delay-75 duration-500 hover:font-extrabold hover:text-emerald-900 lg:pl-8'>
                          <a href='mailto:christine.lyliang@gmail.com' className='cursor-crosshair'>
                            christine.lyliang@gmail
                          </a>
                        </span>
                      </div>
                      <div>
                        <AiFillInstagram style={{ fontSize: '150%' }} className='inline-block' />
                        <span className='text-bold transform pl-2 text-zinc-800 transition delay-75 duration-500 hover:font-extrabold hover:text-emerald-900 lg:pl-8'>
                          <a
                            href='https://www.instagram.com/bagel_doodlees/'
                            className='cursor-crosshair'
                            target='_blank'
                            rel='noreferrer'
                          >
                            @bagel_doodlees
                          </a>
                        </span>
                      </div>
                      <div>
                        <AiFillLinkedin style={{ fontSize: '150%' }} className='inline-block' />
                        <span className='text-bold transform pl-2 text-zinc-800 transition delay-75 duration-500 hover:font-extrabold hover:text-emerald-900 lg:pl-8'>
                          <a
                            href='https://www.linkedin.com/in/christine-liang-0518/'
                            className='cursor-crosshair'
                            target='_blank'
                            rel='noreferrer'
                          >
                            /christine-liang-0518
                          </a>
                        </span>
                      </div>
                      <div>
                        <AiFillGithub style={{ fontSize: '150%' }} className='inline-block' />
                        <span className='text-bold transform pl-2 text-zinc-800 transition delay-75 duration-500 hover:font-extrabold hover:text-emerald-900 lg:pl-8'>
                          <a
                            href='https://github.com/christine-liang502'
                            className='cursor-crosshair'
                            target='_blank'
                            rel='noreferrer'
                          >
                            /christine-liang502
                          </a>
                        </span>
                      </div>
                    </ul>
                    {/* </StaggerAnimate> */}
                  </div>
                </GridOutline>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatedPage>
  );
}
