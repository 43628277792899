import { useEffect, useState } from 'react';
import { BiChevronUp } from 'react-icons/bi';

import { classNames } from '../utils/classNames';

export const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = (): void => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className='fixed bottom-2 right-2'>
      <button
        type='button'
        onClick={scrollToTop}
        className={classNames(
          isVisible ? 'opacity-90' : 'opacity-0',
          'inline-flex items-center rounded-full bg-zinc-300 p-3 text-white shadow-sm transition-opacity hover:cursor-crosshair hover:bg-emerald-900 focus:outline-none focus:ring-2 focus:ring-emerald-800 focus:ring-offset-2'
        )}
      >
        <BiChevronUp className='h-6 w-6' aria-hidden='true' />
      </button>
    </div>
  );
};
