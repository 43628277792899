import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import HomePage from '../Pages/HomePage';
import AboutPage from '../Pages/AboutPage';
import Layout from '../Pages/Layout';
import ContactPage from '../Pages/ContactPage';

export default function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode={'wait'}>
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='contact' element={<ContactPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}
