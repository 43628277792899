import { Link } from 'react-router-dom';
// import Cursor from '../Components/Cursor';

function NavBar() {
  return (
    <header className='sticky z-50 bg-neutral-100'>
      {/* <Cursor /> */}
      <nav>
        <div className='flex items-center justify-around'>
          <div className='flex p-2 font-trispace lg:p-4'>
            <h1 className='mx-auto text-left text-xl font-extralight tracking-tighter md:text-2xl'>
              {/* <span className="box-decoration-slice bg-gradient-to-r from-neutral-500 to-neutral-100 text-white p-4">  */}
              <span className=' text-left md:pr-64 xl:pr-96'>
                <Link to='/' className='cursor-crosshair'>
                  {'// CHRISTINE LIANG'}
                </Link>
              </span>
            </h1>
          </div>
          <div className='flex space-x-7 px-2 font-mont text-xs font-semibold tracking-tighter md:space-x-12'>
            <div className='group relative text-zinc-800'>
              <Link to='/' className='cursor-crosshair'>
                HOME
                <span className='absolute -bottom-1 left-0 h-0.5 w-0 bg-emerald-800 transition-all duration-500 group-hover:w-full'></span>
              </Link>
            </div>
            <div className='group relative text-zinc-800'>
              <Link to='about' className='cursor-crosshair'>
                ABOUT
                <span className='absolute -bottom-1 left-0 h-0.5 w-0 bg-emerald-800 transition-all duration-500 group-hover:w-full'></span>
              </Link>
            </div>
            <div className='group relative text-zinc-800'>
              <Link to='contact' className='cursor-crosshair'>
                CONTACT
                <span className='absolute -bottom-1 left-0 h-0.5 w-0 bg-emerald-800 transition-all duration-500 group-hover:w-full'></span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
