import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function ButtonTag(props: Props) {
  return (
    <li className='mr-1.5 mt-2'>
      <div className='flex items-center rounded-full bg-gray-100 px-3 py-1 font-semibold font-medium leading-5 text-gray-600'>
        {props.children}
      </div>
    </li>
  );
}
