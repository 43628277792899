import { ReactNode } from 'react';

interface Props {
  title?: String;
  children: ReactNode;
}

export default function GridOutline(props: Props) {
  return (
    <div className='md-pb-10 col-span-2 pt-8 pb-8 md:pt-12 lg:pb-16'>
      <div>
        {props.title ? (
          <h4 className='pt-8 pb-4 font-trispace text-4xl font-semibold tracking-wider text-zinc-800'>
            {props.title}
          </h4>
        ) : (
          <></>
        )}
        <div className='border-2 border-solid border-emerald-800 pt-3 pb-4 text-justify text-lg hover:border-dotted md:px-4'>
          {props.children}
        </div>
      </div>
    </div>
  );
}
