const light = {
  titleDiv: 'font-bold',
  ulDiv: 'pl-4',
  ul: 'marker:text-stone-500',
};

const dark = {
  titleDiv: 'font-bold',
  ulDiv: 'pl-4',
  ul: 'marker:text-stone-600',
};

interface Props {
  color: String;
  companyTitle: String;
  jobTitle: String;
  details: String[];
}

export default function ResumeSectionItem(props: Props) {
  return (
    <>
      <div
        className={`text-xl font-semibold italic text-stone-800 ${
          props.color === 'light' ? light.titleDiv : dark.titleDiv
        }`}
      >
        {props.companyTitle}
        <p className='pb-2 underline decoration-double underline-offset-4'>{props.jobTitle}</p>
      </div>
      <div
        className={`pb-2 text-lg font-light ${props.color === 'light' ? light.ulDiv : dark.ulDiv}`}
      >
        <ul
          className={`list-disc space-y-2 marker:text-stone-500 ${
            props.color === 'light' ? light.ul : dark.ul
          }`}
        >
          {props.details.map((element) => (
            <li>{element}</li>
          ))}
        </ul>
      </div>
    </>
  );
}
