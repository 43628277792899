import { motion } from 'framer-motion';
import { ReactNode } from 'react';

const animations = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
  //   transition: { type: 'spring', duration: 0.75, stiffness: 25 },
};

interface Props {
  children: ReactNode;
}
const AnimatedPage = (props: Props) => {
  return (
    <motion.div
      variants={animations}
      initial='intial'
      animate='animate'
      exit='exit'
      transition={{ delay: 0.5, ease: 'easeOut', duration: 1 }}
      //   drag
      //   dragTransition={{
      //     min: 0,
      //     max: 100,
      //     bounceDamping: 8,
      //     bounceStiffness: 100,
      //     timeConstant: 200,
      //   }}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimatedPage;
