import GridOutline from '../Components/GridOutline';
import ResumeSection from '../Components/ResumeSection';
import ResumeSectionItem from '../Components/ResumeSectionItem';
import AnimatedPage from '../Components/AnimatedPage';
import ButtonTag from '../Components/ButtonTag';
import { motion } from 'framer-motion';

export default function AboutPage() {
  return (
    <AnimatedPage>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: 'easeIn' }}
      >
        <div
          className='flex h-screen flex-row items-stretch justify-center self-stretch rounded-b-sm bg-neutral-100 bg-cover text-neutral-700 shadow-md'
          style={{
            backgroundImage: `url(${'/assets/about_self.jpg'})`,
            opacity: 0.9,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <AboutBox />
        <Resume />
      </motion.div>
    </AnimatedPage>
  );
}

function AboutBox() {
  return (
    <div className='container mx-auto'>
      <GridOutline title='_ABOUT'>
        <div className='p-4 md:p-16'>
          <ul className='space-y-2'>
            <li>
              <span className='font-karla text-2xl font-light tracking-tighter'>
                I've always had an immense appreciation for art. Over the years, the different
                mediums I've used to portray my creative visions have varied; from paper and pencil,
                to photography. I'm unceasingly fascinated with the simple concept of creating and
                being able to build something from initially nothing.
                <p className='py-4 md:py-6'>
                  The
                  <span className='px-1 font-semibold italic text-emerald-900 underline underline-offset-2'>
                    intersection between design and development
                  </span>
                  <span className='pl-2'>
                    aligns with my passions for growth in learning while integrating my interests in
                    art.
                  </span>
                </p>
                <p>
                  My educational & work background spans across{' '}
                  <span className='bg-teal-900 pr-1 tracking-normal text-emerald-900 text-neutral-50'>
                    Psychology, Electrical Engineering, IT & Project Management, and Data Science.
                  </span>
                  <span className='pl-2'>As</span> such, I've naturally collaborated with people in
                  a wide variety of industries. From being involved with patients' progress in
                  healthcare clinics to validating spectral data for semiconductors & microchips in
                  electrical engineering, I've diversified my skillsets and have a proven track
                  record of quickly grasping new concepts.
                </p>
                <p className='py-6'>
                  Overall, these experiences have equipped me with a unique perspective on the
                  convergence of technology and human behavior - my goal is to continuously learn &
                  utilize my skills to help drive innovation while keeping human empathy at the
                  core.
                </p>
              </span>
            </li>
          </ul>
        </div>
      </GridOutline>
    </div>
  );
}

function Resume() {
  return (
    <div className='container mx-auto space-y-4'>
      <div className='mx-auto place-content-stretch items-center gap-x-4 lg:grid lg:grid-cols-2 lg:gap-y-4 lg:gap-x-8'>
        <ResumeSection color='light' headerTitle='PROFESSIONAL EXPERIENCE'>
          <ResumeSectionItem
            color='light'
            companyTitle='Alkami Technology, Plano, TX [May 2022 - October 2022]'
            jobTitle='Associate Technical Implementation Engineer'
            details={[
              'Coordinated implementation of client solutions and regular updates across Engineering, Project Management, and Software teams ',
              'Managed client financial databases and ensured seamless data transition when implementing service providers and 10,000+ new users ',
              'Leveraged Jira to track and communicate multiple parallel project components while adhering to agile methodologies ',
              'Supported client mobile app development by linking UI components using Flutter and implementing location services via Google Maps V2 ',
              'Configured backend SQL databases to align with functionality and security parameters of client SaaS packages',
            ]}
          />
          <ResumeSectionItem
            color='light'
            companyTitle='ams Sensors USA, Inc., Plano, TX [May 2021 - October 2021]'
            jobTitle='Data Characterization & Validation Intern'
            details={[
              'Managed ETL process for quality control test data from over 5,000 microchips using APT User and Monochromator.vi hardware ',
              'Evaluated temperature, blocking architecture, and spectral response among multiple microchip channels against client standards ',
              'Developed Excel visualizations using over 1,000 datapoints per microchip to communicate results and commercial implications to clients ',
              'Collaborated with cross-functional teams in an agile manner to gain feedback and resolve issues related to semiconductors',
            ]}
          />
          <ResumeSectionItem
            color='light'
            companyTitle='Precise Circuits, Carrollton, TX [November 2016 - August 2019]'
            jobTitle='Electrical Engineering Technician'
            details={[
              'Spearheaded acquisition of a large oil & gas client and led process of bespoke engineering product design, prototyping, scaling up production, and preparing technical documentation',
              'Designed, built, and implemented an automated system to run quality assurance tests on magnetic components that resulted ~10x increase in efficiency ',
              'Maintained supplier, procurement, and component inventory database to support project planning and order delivery',
            ]}
          />
        </ResumeSection>
        <ResumeSection color='dark' headerTitle='ACTIVITIES & LEADERSHIP'>
          <ResumeSectionItem
            color='dark'
            companyTitle='TAMUhack, Dallas, TX [January 2021]'
            jobTitle='Cloud Pantry: Application Development Project - Participant'
            details={[
              'Designed and developed a Python app to scan grocery items at home and while shopping to flag duplicates and reduce food waste',
              'Applied object recognition techniques using OpenCV and TensorFlow to scan, upload, and classify training photos of grocery items',
              'Created a fully functional user interface with database server connection using Django and PostgreSQL',
            ]}
          />
          <ResumeSectionItem
            color='dark'
            companyTitle='Filipino Student Association @ UT Dallas, Richardson, TX [August 2015 - May 2018]'
            jobTitle='Family Leader'
            details={[
              'Accommodated in corporate outreach and programming for bi-monthly member meetings, social events, and community events',
              'Organized weekly outings and coordinated events to facilitate networking opportunities',
              'Encouraged and served as a mentor for new students which helped them feel more integrated on campus',
            ]}
          />
        </ResumeSection>
      </div>

      <GridOutline title='_EDUCATION'>
        <ul className='space-y-2 px-2'>
          <li>
            <p className='py-2 font-libre text-3xl font-extrabold tracking-wider'>
              University of Texas at Dallas
            </p>
            <p className='pb-1 font-libre text-xl font-bold tracking-wide underline decoration-1 underline-offset-4'>
              Richardson, TX
            </p>
          </li>
          <li>
            <span className='font-trispace text-xl'>2021 </span>
            <span className='font-karla text-xl tracking-tighter'>
              Master of Science in Information Technology & Project Management - Focus in Data
              Science
            </span>
          </li>
          <li>
            <span className='font-trispace text-xl'>2018 </span>
            <span className='font-karla text-xl tracking-tighter'>
              Bachelor of Science in Psychology - Coursework included Human-Computer Interaction
            </span>
          </li>
        </ul>
      </GridOutline>
      <GridOutline title='_HOBBIES & INTERESTS'>
        <ul className='flex flex-wrap px-4 py-2 font-noto font-light'>
          <ButtonTag>PC Building</ButtonTag>
          <ButtonTag>Mechanical Keyboards</ButtonTag>
          <ButtonTag>Art Museums</ButtonTag>
          <ButtonTag>Pen/Pencil Sketches</ButtonTag>
          <ButtonTag>Anime/Manga</ButtonTag>
          <ButtonTag>eSports/Gaming Tournaments</ButtonTag>
          <ButtonTag>Violin & Piano Concertos</ButtonTag>
          <ButtonTag>UX/UI Design</ButtonTag>
          <ButtonTag>Architecture & Interior Design</ButtonTag>
          <ButtonTag>Phone Photography</ButtonTag>
        </ul>
      </GridOutline>
    </div>
  );
}

/* <ul className='space-y-2 px-2'>
          <li>
            <span className='font-libre font-extrabold'>Programming Languages: </span>
            <span className='font-karla font-light tracking-tighter'>
              Python (NumPy, Pandas, Scikit-learn, Matplotlib), JavaScript, TypeScript, React,
              HTML/CSS (Tailwind CSS), SQL (T-SQL, MySQL), RStudio
            </span>
          </li>
          <li>
            <span className='font-libre font-extrabold'>Tech: </span>
            <span className='font-trispace font-light tracking-tighter'>
              VS Code, Jupyter Notebook, PyCharm, Microsoft SQL Server, MySQL, MS Office
            </span>
          </li>
          <li>
            <span className='font-libre font-extrabold'>Database/Data Warehouse: </span>
            <span className='font-trispace font-light tracking-tighter'>
              Oracle, Microsoft SQL Server, MySQL
            </span>
          </li>
          <li>
            <span className='font-libre font-extrabold'>Work Eligibility: </span>
            <span className='font-trispace font-light tracking-tighter'>
              Eligible to work in the U.S. - Permanent Resident
            </span>
          </li>
          <li>
            <span className='font-libre font-extrabold'>Hobbies & Interests: </span>
            <span className='font-trispace font-light tracking-tighter'>
              PC building, mechanical keyboards, art museums, anime, pen/pencil sketches, esports
              tournaments & gaming, violin, piano
            </span>
          </li>
        </ul> */
